import axios from 'axios'
import store from '../store'
import { isEmptyObject } from '@/common'
import { timestampToTime, getDateStr } from '@/common/getDateStr'
import JSONBig from 'json-bigint'
import { getCookie, deleteCookie } from '@/common/cookie'
import { message, Modal } from 'ant-design-vue'
import i18n from '@/utils/lang'

let hasLogout = false

const Axios = axios.create({
  method: 'POST',
  // timeout: 10000, //接口超时加上10s的限制
  withCredentials: true,
  transformResponse: [
    (data) => {
      return JSON.stringify(JSONBig.parse(data))
    },
  ],
})

/**
 * 判断是否是时期格式
 * @param {string} date
 */
const isDate = (date) => {
  let is_date = false
  let pattern_zh = new RegExp('[\u4E00-\u9FA5]+') //判断是否有中文
  let pattern_en = new RegExp('[A-Za-z]+') //判断是否有英文
  let pattern_date = new RegExp(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/) //判断2020-10-10 10:10:10 是否存在
  if (
    !pattern_zh.test(date) &&
    !pattern_en.test(date) &&
    pattern_date.test(date) &&
    isNaN(date) &&
    !isNaN(Date.parse(date)) &&
    (date.length === 10 || date.length === 19)
  ) {
    is_date = true
  }
  return is_date
}

/**
 * 时区处理 请求加1小时，返回减1小时
 * @param {date} date
 * @param {string} type req请求 res返回
 */
const handleDate = (date, type) => {
  let timezoneDiffBeijing = localStorage.getItem('timezoneDiffBeijing') || 0
  let time = +new Date(date) + timezoneDiffBeijing * 3600 * 1000 * (type === 'req' ? 1 : -1)
  return timestampToTime(time)
}

/**
 * 时区处理 请求加1小时，返回减1小时
 * @param {object} data 对象
 * @param {string} type req请求 res返回
 */
const timeZoneProcessing = (data, type = 'req') => {
  if (isEmptyObject(data) || !data) {
    return data
  }
  Object.keys(data).forEach((item) => {
    if (typeof data[item] === 'object') {
      timeZoneProcessing(data[item], type)
    } else if (isDate(data[item])) {
      data[item] = handleDate(data[item], type)
    }
  })
  return data
}

Axios.interceptors.request.use(
  (config) => {
    // loding
    if (config.hasLoading) {
      store.commit('setState', {
        loading: true,
      })
    }

    //method
    config.method = config.type || 'post'

    // path
    let path = config.url

    if (path.startsWith('null')) {
      path = path.replace('null', localStorage.getItem('apiURL'))
    }

    //requestId=rqname+用户名+当前时间(20230906120542)+4位guid()
    let requestId = guid()
    if (path.indexOf('?') > 0) {
      path = path + '&requestId=' + requestId
    } else {
      path = path + '?requestId=' + requestId
    }
    const userSession = store.state.userSession
    // partyId
    let partyId = userSession?.currentBu?.id || userSession?.buList?.[0]?.id

    if (partyId) {
      path = path + '&cpid=' + partyId
    }
    let language = localStorage.getItem('lang') || 'zh'
    let language_mapping = {
      zh: 'zh_CN',
      en: 'en_US',
      th: 'th_TH',
    }
    path += '&language=' + language_mapping[language]
    let token = getCookie('d_session_id')
    config.headers['Authorization'] = token
    config.headers['Content-Type'] = config.contentType ?? 'application/json; charset=UTF-8'
    if (config.responseType) {
      config.headers['Response-Type'] = config.responseType
    }

    config.data = {
      ...(config.data || {}),
      merchantCode: localStorage.getItem('merchantCode'),
    }

    if (config.type?.toLocaleUpperCase() === 'GET') {
      for (let item in config.data) {
        path += `&${item}=${config.data[item]}`
      }
    }

    let data =
      config.data === undefined
        ? JSON.stringify({})
        : config.contentType === false
        ? config.data
        : JSON.stringify(config.data)
    config.data =
      data instanceof FormData ? data : JSON.stringify(timeZoneProcessing(JSON.parse(data), 'req'))

    config.url = path

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

Axios.interceptors.response.use(
  (res) => {
    if (res.config.hasLoading) {
      store.commit('setState', {
        loading: false,
      })
    }

    const value = timeZoneProcessing(JSON.parse(res.data), 'res')
    // console.log('value: ', value)
    if (value.code == 200 || value.code == 1000000) {
      //为了兼容之前的http写法
      if (res.config.success && typeof res.config.success === 'function') {
        res.config.success(value)
      }
      return value
    }

    if (+value.error_code === 5001) {
      //登录超时
      goToLogin()
      return
    }

    if (res.config.fail && typeof res.config.fail === 'function') {
      res.config.fail(value)
      return
    }

    let errMsg = value.subMsg || value.msg || value.message
    if (errMsg) {
      message.error(errMsg)
    }
  },
  (error) => {
    store.commit('setState', {
      loading: false,
    })
    let response = error.response
    console.log('error: ', response)
    try {
      if (response.config.fail && typeof response.config.fail === 'function') {
        response.config.fail(JSON.parse(response.data))
        return Promise.reject(JSON.parse(response.data))
      }
    } catch (err) {
      console.log(err)
    }

    //请求超时
    if (error.code === 'ECONNABORTED' || error.message.includes('timeout')) {
      Modal.warning({
        title: i18n.t('请求超时'),
        content: error.config.url + i18n.t('接口请求超时'),
      })
      return
    }

    try {
      let value = JSON.parse(response?.data)
      if (+value.error_code === 5001) {
        //登录超时
        goToLogin()
        return
      }
      let err = value.msg || value.subMsg || value.message
      if (err && err !== 'null') {
        message.error(err)
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(err)
    }
  }
)

function goToLogin() {
  if (hasLogout) return
  message.destroy()
  message.warning(i18n.t('登录超时'))
  hasLogout = true
  localStorage.clear()
  localStorage.setItem('pageCacheFullPath', location.pathname)
  setTimeout(() => {
    deleteCookie(() => {
      window.location.href = '/login'
    })
  }, 500)
}

function guid() {
  let time = getDateStr(0, true).replace(/\D/g, '')
  let userAccount = localStorage.getItem('userAccount') || 'xxx'
  var s4 = function () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `rqname${userAccount}${time}` + s4()
}

export default Axios
