var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-content"},[_c('div',{staticClass:"table-header"},[(_vm.statusList.length > 0)?_c('div',{staticClass:"row status-list-wrap"},[_c('a-tabs',{attrs:{"default-active-key":""},on:{"change":_vm.setSearchStatus}},[_c('a-tab-pane',{key:""},[_c('template',{slot:"tab"},[_c('span',[_vm._v(_vm._s(_vm.$t('全部')))])])],2),_vm._l((_vm.statusList),function(item){return _c('a-tab-pane',{key:item.code},[_c('template',{slot:"tab"},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])],2)})],2)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataSource.length),expression:"dataSource.length"}],staticClass:"action-list flex-ct"},[_vm._t("header"),(_vm.showSetting)?_c('TableSetting',{attrs:{"columns":_vm.columns},on:{"change":_vm.changeColumns}}):_vm._e()],2)]),_c('div',{staticClass:"table-body"},[_c('a-table',_vm._b({attrs:{"row-selection":_vm.showSelect
          ? {
              selectedRowKeys: _vm.selectedRowKeys,
              type: this.selectType,
              onChange: _vm.onSelectChange,
              getCheckboxProps: (record) => ({
                props: {
                  disabled: record.type === 'summary', // 根据条件禁用选择框
                },
              }),
            }
          : null,"size":_vm.size,"loading":_vm.loading,"scroll":_vm.scroll,"columns":_vm.commonColumns,"data-source":_vm.dataSource,"rowKey":_vm.rowKey ? _vm.rowKey : (record, index) => index,"pagination":false,"customRow":_vm.customRow,"row-class-name":_vm.rowClassName},scopedSlots:_vm._u([{key:"no",fn:function(text, record, index){return [(record.type !== 'summary')?_c('span',[_vm._v(_vm._s(index + 1))]):_c('span',[_vm._v(_vm._s(_vm.$t('合计')))])]}},_vm._l((_vm.columns),function(column){return {key:column.scopedSlots?.customRender,fn:function(text, record, index){return [(record.type !== 'summary' || (record.type === 'summary' && text !== undefined))?_vm._t(column.scopedSlots?.customRender,null,{"text":text,"record":record,"index":index}):_vm._e()]}}})],null,true)},'a-table',_vm.tableProps,false))],1),(_vm.showPagination)?_c('div',{staticClass:"table-footer"},[_c('pagination',{attrs:{"pageNo":_vm.pageNo,"length":_vm.pageSize,"total-count":_vm.total},on:{"paginate":_vm.paginate}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }