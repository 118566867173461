<template>
  <div>
    <a-button :type="type" :disabled="disabled" :loading="checkLoading" @click="checkHandle">
      {{ text }}
    </a-button>

    <ResultModal
      ref="resultModalRef"
      :title="$t('支付结果')"
      :errorMessageList="errorMessageList"
    />
  </div>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import debounce from '@/common/debounce'
import ResultModal from '@/component/resultModal'
export default {
  components: { ResultModal },
  props: {
    api: {
      type: String,
    },
    type: {
      type: String,
      default: 'primary',
    },
    text: { type: String },
    params: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkHandle: debounce(async function () {
      this.checkLoading = true
      let url = this.api ? api[this.api] : api.payPaymentNote
      await http({
        url,
        data: {
          ...this.params,
        },
        success: (res) => {
          if (res.success) {
            if (res.result.error_message_list?.length > 0) {
              this.errorMessageList = res.result.error_message_list
              this.$refs.resultModalRef.show()
            } else {
              this.$success({
                title: this.$t(`成功`),
                content: this.text + this.$t(`成功`),
              })
            }
            this.$emit('success')
          }
        },
      })
      this.checkLoading = false
    }),
  },
  data() {
    return {
      checkLoading: false,
      errorMessageList: [],
    }
  },
}
</script>
