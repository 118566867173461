<template>
  <div :id="id" class="modal-box" :class="{ 'hidden-box': !showMore }">
    <div class="info-title">
      <div @click="showMore = !showMore">
        <a-icon class="icon" type="caret-down" v-if="showMore" />
        <a-icon class="icon" type="caret-up" v-else />
        <span class="pl4 title">{{ title }}</span>
        <span class="fz14 ml10 text-link" v-if="showCopy" @click.stop.prevent="copyOrder">
          {{ $t('复制单据') }}
        </span>
      </div>
      <slot name="header"></slot>
    </div>
    <div class="box-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: true,
    }
  },
  methods: {
    copyOrder() {
      this.$emit('copyOrder')
    },
  },
}
</script>

<style scoped lang="scss">
.modal-box {
  overflow: hidden;
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  .info-title {
    font-size: 16px;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.85);
    line-height: 18px;
    margin-bottom: 15px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: space-between;
  }
  .icon,
  .title {
    cursor: pointer;
  }
  .h0 {
    height: 0;
  }
  .box-content {
    height: auto;
    overflow: hidden;
  }
}
.hidden-box {
  .info-title {
    margin-bottom: 0;
  }
  .box-content {
    height: 0;
  }
}
</style>
