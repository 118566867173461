<template>
  <a-popover v-model="visible" :title="$t('勾选展示/拖拽排序')" trigger="click" placement="left">
    <div slot="content" class="column-list">
      <!-- 左固定 -->
      <div class="text-title" v-if="leftColumns.length > 0">{{ $t('固定在左侧') }}</div>
      <div class="sub-list left-list" v-if="leftColumns.length > 0">
        <draggable :list="leftColumns" group="left" ghost-class="ghost" @end="submitColumns">
          <transition-group>
            <div
              class="column-item flex-ct"
              v-for="element in leftColumns"
              :key="element.dataIndex"
            >
              <a-checkbox :checked="!element.hide" @change="setShow(element)"></a-checkbox>
              <span class="ml8 mr10">{{ element.title }}</span>
              <a-tooltip>
                <template slot="title">{{ $t('不固定') }}</template>
                <a-icon
                  class="sort-icon"
                  type="vertical-align-middle"
                  style="margin-left: auto"
                  @click="setFixed(element, undefined)"
                />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">{{ $t('固定在右侧') }}</template>
                <a-icon
                  class="sort-icon"
                  type="vertical-align-bottom"
                  @click="setFixed(element, 'right')"
                />
              </a-tooltip>
            </div>
          </transition-group>
        </draggable>
      </div>
      <!-- 不固定 -->
      <div
        class="text-title"
        v-if="centerColumns.length > 0 && (leftColumns.length > 0 || rightColumns.length > 0)"
      >
        {{ $t('不固定') }}
      </div>
      <div class="sub-list center-list" v-if="centerColumns.length > 0">
        <draggable :list="centerColumns" group="center" ghost-class="ghost" @end="submitColumns">
          <transition-group>
            <div
              class="column-item flex-ct"
              v-for="element in centerColumns"
              :key="element.dataIndex"
            >
              <a-checkbox :checked="!element.hide" @change="setShow(element)"></a-checkbox>
              <span class="ml8 mr10">{{ element.title }}</span>
              <a-tooltip>
                <template slot="title">{{ $t('固定在左侧') }}</template>
                <a-icon
                  class="sort-icon"
                  type="vertical-align-top"
                  style="margin-left: auto"
                  @click="setFixed(element, 'left')"
                />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">{{ $t('固定在右侧') }}</template>
                <a-icon
                  class="sort-icon"
                  type="vertical-align-bottom"
                  @click="setFixed(element, 'right')"
                />
              </a-tooltip>
            </div>
          </transition-group>
        </draggable>
      </div>
      <!-- 右固定 -->
      <div class="text-title" v-if="rightColumns.length > 0">{{ $t('固定在右侧') }}</div>
      <div class="sub-list right-list" v-if="rightColumns.length > 0">
        <draggable :list="rightColumns" group="right" ghost-class="ghost" @end="submitColumns">
          <transition-group>
            <div
              class="column-item flex-ct"
              v-for="element in rightColumns"
              :key="element.dataIndex"
            >
              <a-checkbox :checked="!element.hide" @change="setShow(element)"></a-checkbox>
              <span class="ml8 mr10">{{ element.title }}</span>
              <a-tooltip>
                <template slot="title">{{ $t('固定在左侧') }}</template>
                <a-icon
                  class="sort-icon"
                  type="vertical-align-top"
                  style="margin-left: auto"
                  @click="setFixed(element, 'left')"
                />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">{{ $t('不固定') }}</template>
                <a-icon
                  class="sort-icon"
                  type="vertical-align-middle"
                  @click="setFixed(element, undefined)"
                />
              </a-tooltip>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>

    <a-tooltip>
      <template slot="title">{{ $t('列设置') }}</template>
      <a-icon
        type="setting"
        style="margin-left: auto; cursor: pointer; font-size: 20px"
        class="mr20"
        @click="showSort"
      />
    </a-tooltip>
  </a-popover>
</template>

<script>
import draggable from 'vuedraggable'
import deepClone from '@/common/deepClone'
export default {
  components: {
    draggable,
  },
  props: { columns: {} },
  data() {
    return {
      visible: false,
      centerColumns: [],
      leftColumns: [],
      rightColumns: [],
    }
  },
  methods: {
    showSort() {
      this.$nextTick(() => {
        let list = deepClone(this.columns)
        //筛选出fixed: "left" 和 fixed: "right" 的columns 剩下的放到initColumns
        this.leftColumns = list.filter((item) => item.fixed === 'left')
        this.rightColumns = list.filter((item) => item.fixed === 'right')
        this.centerColumns = list.filter((item) => !item.fixed)
      })
    },
    submitColumns() {
      this.$nextTick(() => {
        let list = [...this.leftColumns, ...this.centerColumns, ...this.rightColumns]
        this.$emit('change', list)
        this.showSort()
      })
    },
    setShow(element) {
      this.$set(element, 'hide', !element.hide)
      this.submitColumns()
    },
    setFixed(element, type) {
      this.$set(element, 'fixed', type)
      this.submitColumns()
    },
  },
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.column-list {
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.column-list::-webkit-scrollbar {
  display: none;
}
.column-item {
  line-height: 30px;
  cursor: pointer;
  &:hover {
    .sort-icon {
      visibility: visible;
    }
  }
}
.text-title {
  font-size: 12px;
  color: #999;
}
.sort-icon {
  visibility: hidden;
  padding: 4px;
}
.sub-list {
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
