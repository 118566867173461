import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import en_US from 'ant-design-vue/lib/locale-provider/en_US'
import th_TH from 'ant-design-vue/lib/locale-provider/th_TH'
import { getRouter } from '@/main'
import deepClone from '@/common/deepClone'

export default {
  setState(state, data) {
    setState(state, data)
  },
  addPane(state, { view, name, query, params, route }) {
    const isCtrlDown = window.event.ctrlKey || window.event.metaKey //是否按下ctrl键或者cmd键
    let index = state.panes.findIndex((item) => view.includes(item.view))
    // let index = state.panes.findIndex((item) => item.view === view)
    if (index === -1) {
      let panes = deepClone(state.panes)
      if (isCtrlDown) {
        panes.unshift({ view, name, query, params })
      } else {
        panes.push({ view, name, query, params })
      }
      setState(state, { panes: deepClone(panes) })
    } else {
      let panes = state.panes
      panes[index].name = name
      panes[index].query = query
      panes[index].params = params
      setState(state, { panes: deepClone(panes) })
    }
    if (isCtrlDown) {
      document.querySelector('.ant-tabs-nav').style.transform = 'translate3d(0px, 0px, 0px)'
      return
    }
    let router = getRouter()
    route && router.push(route)
  },
  removePane(state, { activeKey, targetKey, callback }) {
    let lastIndex
    state.panes.forEach((pane, i) => {
      if (pane.view === targetKey) {
        lastIndex = i - 1
      }
    })
    const panes = state.panes.filter((pane) => pane.view !== targetKey)
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].view
      } else {
        activeKey = panes[0].view
      }
    }
    state.panes = panes
    localStorage.setItem('panes', JSON.stringify(panes))
    callback && callback(activeKey)
  },
  changeLang(state, lang) {
    let locale = zh_CN
    switch (lang) {
      case 'zh':
        locale = zh_CN
        break
      case 'en':
        locale = en_US
        break
      case 'th':
        locale = th_TH
        break
      default:
        locale = zh_CN
        break
    }
    setState(state, {
      lang,
      locale,
    })
  },
  setOrderDetail(state, { orderId, data }) {
    state.orderDetailStateInfo[orderId] = data
  },
}

const setState = (state, data) => {
  for (let item in data) {
    state[item] = data[item]
    localStorage.setItem(
      item,
      typeof data[item] === 'object' && data[item] !== null
        ? JSON.stringify(data[item])
        : data[item]
    )
  }
}
