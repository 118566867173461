import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Zh from '@/assets/lang/zh.json'
import En from '@/assets/lang/en.json'

Vue.use(VueI18n)

const lang = localStorage.getItem('lang') || 'zh'

const i18n = new VueI18n({
  locale: lang, // 语言标识
  messages: {
    zh: Zh,
    en: En,
  },
})

export default i18n
