import Vue from 'vue'
import { message } from 'ant-design-vue'
import i18n from './lang'
import {
  Alert,
  Icon,
  Card,
  Divider,
  Row,
  Col,
  Table,
  Button,
  FormModel,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Checkbox,
  Radio,
  Upload,
  Popover,
  Modal,
  Tabs,
  Tag,
  Steps,
  Switch,
  Popconfirm,
  AutoComplete,
  Progress,
  Timeline,
  Tooltip,
  Dropdown,
  Menu,
  Spin,
  Tree,
  Empty,
} from 'ant-design-vue'

const antdComponents = [
  Alert,
  Icon,
  Card,
  Divider,
  Row,
  Col,
  Table,
  Button,
  FormModel,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Checkbox,
  Radio,
  Popover,
  Upload,
  Modal,
  Tabs,
  Tag,
  Steps,
  Switch,
  Popconfirm,
  AutoComplete,
  Progress,
  Timeline,
  Tooltip,
  Dropdown,
  Menu,
  Spin,
  Tree,
  Empty,
]
antdComponents.forEach((item) => {
  Vue.use(item)
})
Vue.prototype.$message = message
Vue.prototype.$info = function (value) {
  if (typeof value === 'string') {
    Modal.info({
      title: i18n.t('通知'),
      content: value,
    })
    return
  }
  Modal.info(value)
}
Vue.prototype.$success = function (value) {
  if (typeof value === 'string') {
    Modal.success({
      title: i18n.t('成功'),
      content: value,
    })
    return
  }
  Modal.success(value)
}
Vue.prototype.$error = function (value) {
  if (typeof value === 'string') {
    Modal.error({
      title: i18n.t('错误'),
      content: value,
    })
    return
  }
  Modal.error(value)
}
Vue.prototype.$warning = function (value) {
  if (typeof value === 'string') {
    Modal.warning({
      title: i18n.t('警告'),
      content: value,
    })
    return
  }
  Modal.warning(value)
}
Vue.prototype.$confirm = function (value) {
  if (typeof value === 'string') {
    Modal.confirm({
      title: i18n.t('确定'),
      content: value,
    })
    return
  }
  Modal.confirm(value)
}
Vue.prototype.$destroyAll = function (value) {
  if (typeof value === 'string') {
    Modal.destroyAll({
      title: i18n.t('错误'),
      content: value,
    })
    return
  }
  Modal.destroyAll(value)
}
