<template>
  <a-modal
    width="800px"
    :title="title"
    v-model="resultVisible"
    :keyboard="false"
    :maskClosable="false"
    @cancel="cancelHandle"
    :footer="null"
  >
    <template v-if="errorMessageList.length > 0">
      <div class="infoTitle">
        <div class="lineLeft"></div>
        {{ $t('错误信息') }}
      </div>
      <CommonTable
        :columns="errorColumns"
        :dataSource="errorMessageList"
        :showPagination="false"
        :showSetting="false"
      >
        <template v-slot:msg="{ text }">
          <span class="text-danger">{{ text }}</span>
        </template>
      </CommonTable>
    </template>
    <template v-if="successMessageList.length > 0">
      <div class="infoTitle mt20">
        <div class="lineLeft"></div>
        {{ title }}
      </div>
      <CommonTable
        :columns="successColumns"
        :dataSource="successMessageList"
        :showPagination="false"
        :showSetting="false"
      >
        <template v-slot:voucher_sn="{ text, record }">
          <span class="text-link" @click="goJump(record)">{{ text }}</span>
        </template>
      </CommonTable>
    </template>
  </a-modal>
</template>

<script>
export default {
  props: {
    errorMessageList: { type: Array, default: () => [] },
    successMessageList: { type: Array, default: () => [] },
    title: { type: String },
    successColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultVisible: false,
    }
  },
  computed: {
    errorColumns() {
      return [
        {
          title: this.$t('单据号'),
          dataIndex: 'doc_sn',
          width: 300,
        },
        {
          title: this.$t('结果'),
          dataIndex: 'msg',
          scopedSlots: {
            customRender: 'msg',
          },
        },
      ]
    },
  },
  methods: {
    show() {
      this.resultVisible = true
    },
    cancelHandle() {
      this.resultVisible = false
      this.$emit('cancel')
    },
    goJump(record) {
      this.resultVisible = false
      this.$emit('jump', record)
    },
  },
}
</script>
