<template>
  <div>
    <a-dropdown>
      <a-menu slot="overlay" @click="pushHandle">
        <a-menu-item key="UP">上查</a-menu-item>
        <a-menu-item key="BELOW">下查</a-menu-item>
      </a-menu>
      <a-button type="primary" :disabled="disabled" :loading="loading">
        {{ $t('联查') }}
        <a-icon type="down" />
      </a-button>
    </a-dropdown>

    <a-modal
      :title="$t('联查单据列表')"
      v-model="resultVisible"
      width="1000px"
      :footer="null"
      :keyboard="false"
      :maskClosable="false"
    >
      <CommonTable
        :columns="resultColumns"
        :dataSource="resultList"
        :showPagination="false"
        :showSetting="false"
      >
        <template v-slot:doc_sn="{ text, record }">
          <span class="text-link" @click="goJump(record)">{{ text }}</span>
        </template>
      </CommonTable>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import { jumpSoucePage } from '@/common'
export default {
  props: {
    id: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    docType: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      resultVisible: false,
      resultList: [],
    }
  },
  computed: {
    ...mapGetters(['documentTypeMapping']),
    resultColumns() {
      return [
        {
          title: this.$t('单据号'),
          dataIndex: 'doc_sn',
          scopedSlots: {
            customRender: 'doc_sn',
          },
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'doc_type',
          customRender: (text) => this.documentTypeMapping[text],
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
          customRender: (text, record) => {
            let typeName = this.changeFirstLetterToLower(record.doc_type) + 'StatusMapping'
            return this.$store.getters[typeName][text]
          },
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'business_unit_name',
        },
        {
          title: this.$t('创建人'),
          dataIndex: 'created_user',
        },
        {
          title: this.$t('创建时间'),
          dataIndex: 'created_at',
        },
      ]
    },
  },
  methods: {
    changeFirstLetterToLower(str) {
      if (str.length === 0) {
        return str // 如果字符串为空，直接返回
      }
      // 将第一个字母转换为小写，并将其余部分保持不变
      return str[0].toLowerCase() + str.slice(1)
    },
    async pushHandle({ key }) {
      this.loading = true
      await http({
        url: api.findRelatedDoc,
        data: {
          findType: key,
          docType: this.docType,
          id: this.id,
        },
        success: (res) => {
          let list = res.result?.doc_list || []
          if (list.length === 0) {
            this.$message.warning(this.$t('没有相关联的单据'))
          } else if (list.length === 1) {
            //直接跳转
            jumpSoucePage(list[0].doc_sn, list[0].id)
          } else {
            this.resultVisible = true
            this.resultList = list
          }
        },
      })
      this.loading = false
    },
    goJump(record) {
      this.resultVisible = false
      jumpSoucePage(record.doc_sn, record.id)
    },
  },
}
</script>

<style lang="less" scoped>
.text-note {
  color: #999;
  font-size: 12px;
}
</style>
