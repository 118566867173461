const baseURL = "https://testfms.geezdata.com/api/";
const inventoryWebEntry = "https://testinventory.shopspade.com/api/";
const scmURL = "https://testscm.shopspade.com/api/";
const reportURL = "https://testtask.shopspade.com/api/";
const goodsURL = "https://testproduct.shopspade.com/api/";
const productURL = "https://testproduct.shopspade.com/api/";
const regionURL = "https://testregion.shopspade.com/api/";
const shopURL = "https://testshop.shopspade.com/api/";
const orderURL = "https://testorder.shopspade.com/api/";
const inventoryURL = "https://testinventory.shopspade.com/api/";
const idaasURL = "https://testidaas.shopspade.com";

const singleSignOnURL =
  idaasURL + '/api/open/oauth/authorize?app_key=spade_gateway_001&response_type=code&scope=FMS&terminal=PC&redirect_uri=' +
  window.location.origin + "&idaas_uri=" + idaasURL

const singleSignOutURL = idaasURL + '/logout'

export {
  baseURL,
  inventoryWebEntry,
  scmURL,
  reportURL,
  goodsURL,
  regionURL,
  productURL,
  shopURL,
  orderURL,
  inventoryURL,
  idaasURL,
  singleSignOnURL,
  singleSignOutURL
};
